import { db, storage, timestamp } from '@/firebase/config'

const uploadFile = async (file, filename, userId, type, urlStore) => {
  try {
    const storageRef = storage.ref('user_files/' + userId + '/' + type + '/' + file.name);
    let url;

    await storageRef.put(file).then(async () => {
      url = await storageRef.getDownloadURL();
      urlStore.value = url;
      const uploadedAt = timestamp();
      await db.collection(type).add({ url, filename, uploadedAt, userId });
    })

    // console.log("uploadFile activated")
  } catch (error) {
    alert("uploadFile: " + error)
  }
};

export default uploadFile
