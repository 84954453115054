import { ref } from 'vue'
import { auth } from '@/firebase/config'
import getUser from '../getUser'

const UCPerror = ref(null)

const changePassword = async (curPassword, newPassword) => {
  UCPerror.value = null
  // console.log("Changing", newPassword)

  try {
    const { user } = getUser()
    // console.log(user.value.email)
    await auth.signInWithEmailAndPassword(user.value.email, curPassword)
    const res = await user.value.updatePassword(newPassword)
    UCPerror.value = null
    return res
  }
  catch(err) {
    // console.log(err.message)
    if (err.message == "The password is invalid or the user does not have a password.") {
      UCPerror.value = 'ChangePassword Failed: Incorrect login credentials'
    } else {
      UCPerror.value = 'ChangePassword Failed: ' + err.message
    }
    
  }
}

const useChangePassword = () => {
  return { UCPerror, changePassword }
}

export default useChangePassword
