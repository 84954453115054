import { ref } from 'vue'
import { auth } from '@/firebase/config'
import getUser from '../getUser'
import { db } from '@/firebase/config'

const UCEerror = ref(null)

const changeEmail = async (password, newEmail) => {
  UCEerror.value = null
  // console.log("Changing", newEmail)

  try {
    const { user } = getUser()
    await auth.signInWithEmailAndPassword(user.value.email, password)
    const res = await user.value.updateEmail(newEmail)
    await db.collection("Profiles").doc(user.value.uid).update({
      email:newEmail
    })
    // console.log("Changed", newEmail)
    UCEerror.value = null
    return res
  }
  catch(err) {
    // console.log(err.message)
    if (err.message == "The password is invalid or the user does not have a password.") {
      UCEerror.value = 'ChangePassword Failed: Incorrect login credentials'
    } else {
      UCEerror.value = 'ChangePassword Failed: ' + err.message
    }
    
  }
}

const useChangeEmail = () => {
  return { UCEerror, changeEmail }
}

export default useChangeEmail
