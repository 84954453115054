import { db, storage } from '@/firebase/config'

const deleteFile = async (userId, type) => {
  try {
    const storageRef = storage.ref('user_files/' + userId + '/' + type);

    const deleteFile = (pathToFile, fileName) => {
      const ref = storage.ref(pathToFile);
      return ref.child(fileName).delete();
    }

    await db.collection(type).where("userId", "==", userId).get().then(async docs => {
      let promises = [];
      docs.forEach(doc => {
        promises.push(db.collection(type).doc(doc.id).delete());
      })
      return Promise.all(promises)
    });

    await storageRef.listAll().then(dir => {
      let promises = []
      dir.items.forEach(fileRef => {
        promises.push(deleteFile(storageRef.fullPath, fileRef.name));
      })
      return Promise.all(promises)
    });

    // console.log("deleteFile activated")
  } catch (error) {
    alert("deleteFile: " + error)
  }
};

export default deleteFile
